<template>
    <div class="page-wrapper">
                <div class="search-page ">
            <div class="container ">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb pl-0">
                        <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.search-results') }}</li>
                    </ol>
                </nav>
                <div class="page-title  text-center text-md-left mb-lg-5 mb-0">
                    {{ $t('search-results.title') }}
                </div>
            </div>
        </div>

        <div class="search-page">
            <section class="coursers">
                <div class="container pb-5">
                    <div class="row form-row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="area">{{ $t('search-results.region-label') }}</label>
                                <select name="" id="" class="form-control">
                                    <option :value="$t('search-results.region-placeholder')">
                                        {{ $t('search-results.region-placeholder') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="subject">{{ $t('search-results.lesson-label') }}</label>
                                <select name="" id="" class="form-control">
                                    <option :value="$t('search-results.lesson-placeholder')">
                                        {{ $t('search-results.lesson-placeholder') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="teacher">{{ $t('search-results.teacher-label') }}</label>
                                <select name="" id="" class="form-control">
                                    <option :value="$t('search-results.teacher-placeholder')">
                                        {{ $t('search-results.teacher-placeholder') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 d-flex align-items-end">
                            <div class="form-group w-100 mt-4 mt-lg-0 text-center">
                                <div class="btn btn-primary">
                                    {{ $t('search-results.search-button') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row sort-row">
                        <div class="col-4 ml-auto">
                            <div class="form-group row align-items-center justify-content-end pr-4">
                                <div class="text-right pr-0">
                                    <label for="sort" class="select-label">{{ $t('search-results.sort-by') }}</label>
                                </div>
                                <div class=" pl-1">
                                    <select class="form-control pl-2" id="sort">
                                        <option value=""> {{ $t('search-results.popular') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 px-2">
                            <div v-for="teacher in teachers" :key="teacher.id">
                                <div class="card">
                                    <div class="card-image ">
                                        <img :src="'/images/' + $t(teacher.img)" alt="" class="w-100 ">
                                    </div>
                                    <div class="card-content">
                                        <div class="card-content-title">
                                            {{ $t('teacher.name') }}
                                        </div>
                                        <div class="card-content-subtitle">
                                            {{ $t('teacher.course') }}
                                        </div>
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5 3.39844C5.93845 3.39844 6.69922 2.63767 6.69922 1.69922C6.69922 0.760766 5.93845 0 5 0C4.06155 0 3.30078 0.760766 3.30078 1.69922C3.30078 2.63767 4.06155 3.39844 5 3.39844Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M8.4375 3.39845C9.03077 3.39845 9.51172 2.9175 9.51172 2.32422C9.51172 1.73095 9.03077 1.25 8.4375 1.25C7.84423 1.25 7.36328 1.73095 7.36328 2.32422C7.36328 2.9175 7.84423 3.39845 8.4375 3.39845Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M1.5625 3.39845C2.15577 3.39845 2.63672 2.9175 2.63672 2.32422C2.63672 1.73095 2.15577 1.25 1.5625 1.25C0.969225 1.25 0.488281 1.73095 0.488281 2.32422C0.488281 2.9175 0.969225 3.39845 1.5625 3.39845Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M2.6209 4.36314C2.19805 4.0167 1.8151 4.06256 1.32617 4.06256C0.594922 4.06256 0 4.65396 0 5.38072V7.51372C0 7.82934 0.257617 8.08598 0.574414 8.08598C1.94211 8.08598 1.77734 8.11073 1.77734 8.027C1.77734 6.51556 1.59832 5.40714 2.6209 4.36314Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M5.46485 4.06992C4.61087 3.99869 3.86858 4.07074 3.22833 4.59922C2.1569 5.45743 2.36309 6.61296 2.36309 8.02657C2.36309 8.40058 2.66739 8.71056 3.04708 8.71056C7.16977 8.71056 7.33386 8.84355 7.57833 8.30216C7.6585 8.11907 7.63653 8.17726 7.63653 6.42579C7.63653 5.03465 6.43198 4.06992 5.46485 4.06992Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M8.67363 4.06251C8.18203 4.06251 7.80119 4.01712 7.37891 4.36309C8.39385 5.39934 8.22246 6.43213 8.22246 8.02695C8.22246 8.1112 8.08568 8.08593 9.40488 8.08593C9.73301 8.08593 9.9998 7.82011 9.9998 7.49335V5.38067C9.9998 4.65391 9.40488 4.06251 8.67363 4.06251Z"
                                                            fill="#666666" />
                                                    </svg>

                                                </span>
                                                <span>
                                                    {{ $t('teacher.students') }}
                                                </span>
                                            </li>
                                            <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.47525 7.80283H7.66926V0H0V10H5.47525V7.80283ZM3.83463 6.95963H1.75129V6.37369H3.83463V6.95963ZM4.45963 7.7474V8.33334H3.20963V7.7474H4.45963ZM5.91797 5.70963H1.75129V5.12369H5.91797V5.70963ZM5.91797 4.45963H1.75129V3.87369H5.91797V4.45963ZM5.91797 3.00131H1.75129V2.41537H2.97906C2.93893 2.31232 2.91666 2.20041 2.91666 2.08334C2.91666 1.57717 3.32846 1.16537 3.83463 1.16537C4.3408 1.16537 4.7526 1.57717 4.7526 2.08334C4.7526 2.20041 4.73033 2.31232 4.6902 2.41537H5.91797V3.00131Z"
                                                            fill="#666666" />
                                                    </svg>
                                                </span>
                                                <span>
                                                    {{ $t('teacher.lessons') }}
                                                </span>
                                            </li>
                                            <!-- <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.58994 3.23898C9.55916 3.14413 9.47728 3.07501 9.3787 3.06068L6.60933 2.65787L5.37079 0.14602C5.32672 0.0566021 5.23573 0 5.13612 0C5.03649 0 4.94552 0.0566021 4.90142 0.14602L3.66284 2.65787L0.893514 3.06068C0.794951 3.07501 0.713033 3.14413 0.682255 3.23896C0.65146 3.33381 0.677143 3.43792 0.748488 3.50751L2.75234 5.46272L2.27936 8.22354C2.26251 8.32183 2.30288 8.42113 2.38347 8.47976C2.42906 8.51291 2.48307 8.52978 2.53733 8.52978C2.57899 8.52978 2.6208 8.51984 2.65908 8.49969L5.1361 7.19618L7.61301 8.49967C7.70121 8.54607 7.80804 8.53835 7.88864 8.47974C7.96923 8.42113 8.00962 8.3218 7.99278 8.22351L7.51965 5.46272L9.52373 3.50749C9.59505 3.43792 9.62075 3.33381 9.58994 3.23898Z"
                                                            fill="#666666" />
                                                    </svg>
                                                </span>
                                                <span>
                                                    {{ $t('teacher.rating') }}
                                                </span>
                                            </li> -->
                                        </ul>
                                        <div class="card-content-text">
                                            <p>{{ $t('teacher.desc') }}</p>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="card-footer-title">
                                            {{ $t(teacher.feedbacks) }}
                                        </div>
                                        <button class="btn btn-danger">
                                            {{ $t('search-results.more-button') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination pagination-lg">
                                <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">
                                            <svg width="9" height="17" viewBox="0 0 9 17" stroke="#473F95"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.97656 1.08301L1.71094 8.35645L8.97656 15.6299L8.27344 16.333L0.289061 8.35645L8.27344 0.379884L8.97656 1.08301Z"
                                                    fill="#473F95" />
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                                <li class="page-count">
                                    <span>1</span>
                                    <span>10</span>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">
                                            <svg width="9" height="17" viewBox="0 0 9 17" stroke="#473F95"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0234395 1.08301L7.28906 8.35645L0.0234383 15.6299L0.726563 16.333L8.71094 8.35645L0.726565 0.379884L0.0234395 1.08301Z"
                                                    fill="white" />
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            teachers: [
                {
                'id': "1",
                'name': 'search-results.teacher-1-name',
                'course': 'search-results.teacher-1-course',
                'students': 'search-results.teacher-1-students',
                'lessons': 'search-results.teacher-1-lessons',
                'rating': 'search-results.teacher-1-rating',
                'desc': 'search-results.teacher-1-desc',
                'feedbacks': 'search-results.teacher-1-feedbacks',
                'img': 'search-results.teacher-1-img',
                },
                {
                'id': "2",
                'name': 'search-results.teacher-2-name',
                'course': 'search-results.teacher-2-course',
                'students': 'search-results.teacher-2-students',
                'lessons': 'search-results.teacher-2-lessons',
                'rating': 'search-results.teacher-2-rating',
                'desc': 'search-results.teacher-2-desc',
                'feedbacks': 'search-results.teacher-2-feedbacks',
                'img': 'search-results.teacher-2-img',
                },
                {
                'id': "3",
                'name': 'search-results.teacher-3-name',
                'course': 'search-results.teacher-3-course',
                'students': 'search-results.teacher-3-students',
                'lessons': 'search-results.teacher-3-lessons',
                'rating': 'search-results.teacher-3-rating',
                'desc': 'search-results.teacher-3-desc',
                'feedbacks': 'search-results.teacher-3-feedbacks',
                'img': 'search-results.teacher-3-img',
                },
                {
                'id': "4",
                'name': 'search-results.teacher-4-name',
                'course': 'search-results.teacher-4-course',
                'students': 'search-results.teacher-4-students',
                'lessons': 'search-results.teacher-4-lessons',
                'rating': 'search-results.teacher-4-rating',
                'desc': 'search-results.teacher-4-desc',
                'feedbacks': 'search-results.teacher-4-feedbacks',
                'img': 'search-results.teacher-4-img',
                },
            ],
        }
    }
}
</script>
<style lang="less">
.card {
    &-image {
        height: 122px;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>